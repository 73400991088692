import { Box, Text } from "@chakra-ui/react";
import React from "react";

export default function StoryLine({ text }) {
  return (
    <Text mb={2} variant={"storyLine"}>
      {text}
    </Text>
  );
}
