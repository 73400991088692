import React from "react";
import { Helmet } from "react-helmet";

export default function Head(props) {
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />

        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {props.roboto ? (
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap"
            rel="stylesheet"
          />
        ) : (
          ""
        )}
        {props.racing ? (
          <link
            href="https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap"
            rel="stylesheet"
          />
        ) : (
          ""
        )}

        {props.bebas ? (
          <link
            href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap"
            rel="stylesheet"
          />
        ) : (
          ""
        )}

        {props.poppins ? (
          <link
            href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,500&display=swap"
            rel="stylesheet"
          />
        ) : (
          ""
        )}
        {props.openSans ? (
          <link
            href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap"
            rel="stylesheet"
          />
        ) : (
          ""
        )}
        {props.fellEnglish ? (
          <link
            href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
            rel="stylesheet"
          />
        ) : (
          ""
        )}

        {props.prohibition && (
          <link rel="stylesheet" href="https://use.typekit.net/psz6nle.css" />
        )}
      </Helmet>
    </>
  );
}
