export const Store = [
  {
    stores: [
      {
        name: "Migros Gland",
        address: "Rue Mauverney, 22",
        postal_code: "1196",
        city: "Gland",
      },
      {
        name: "Migros Do it",
        address: "Chem. de la Fontaine, 1",
        postal_code: "1260",
        city: "Nyon-la Combe",
      },
      {
        name: "Migros PrintShop",
        address: "Centre Commercial la Combe, 6",
        postal_code: "1260",
        city: "Nyon-la Combe",
      },
      {
        name: "Migros Versoix",
        address: "Chem. de l'Ancien-Péage, 1",
        postal_code: "1290",
        city: "Versoix",
      },
      {
        name: "Migros Vésenaz",
        address: "Rte de Thonon, 55",
        postal_code: "1222",
        city: "Vésenaz",
      },
      {
        name: "Migros Le Grand-Saconnex",
        address: "Rue Sonnex, 20",
        postal_code: "1218",
        city: "Le Grand-Saconnex",
      },
      {
        name: "Migros Meyrin",
        address: "Av. de Feuillasse, 24",
        postal_code: "1217",
        city: "Meyrin",
      },
      {
        name: "Migros ARCenter",
        address: "Rte de Peney, 2-4",
        postal_code: "1214",
        city: "Vernier",
      },
      {
        name: "Migros Centre Balexert",
        address: "Av. Louis-Casaï, 27",
        postal_code: "1209",
        city: "Vernier",
      },
      {
        name: "Migros Genève Servette",
        address: "Rue de la Servette, 75",
        postal_code: "1202",
        city: "Genève",
      },
      {
        name: "Migros Genève Lausanne",
        address: "Rue de Lausanne, 18-20",
        postal_code: "1201",
        city: "Genève",
      },
      {
        name: "Migros Genève Europe",
        address: "Prom. de l'Europe, 11",
        postal_code: "1203",
        city: "Genève",
      },
      {
        name: "Migros Petit-Lancy",
        address: "Rue des Bossons, 78/80",
        postal_code: "1213",
        city: "Petit-Lancy",
      },
      {
        name: "Migros Carouge Gustave-Revilliod",
        address: "Rue Gustave-Revilliod, 4",
        postal_code: "1227",
        city: "Carouge",
      },
      {
        name: "MParc La Praille",
        address: "Av. Vibert, 32",
        postal_code: "1227",
        city: "Carouge",
      },
      {
        name: "Migros Carouge Vibert",
        address: "Av. Vibert, 29",
        postal_code: "1227",
        city: "Carouge",
      },
      {
        name: "Migros Chêne-Bourg",
        address: "Av. de Bel-Air, 12",
        postal_code: "1225",
        city: "Chêne-Bourg",
      },
      {
        name: "Migros Thonex",
        address: "Rue de Genève, 92",
        postal_code: "1226",
        city: "Thônex",
      },
      {
        name: "Migros Vésenaz",
        address: "Rte de Thonon, 55",
        postal_code: "1222",
        city: "Vésenaz",
      },
    ],
  },
];
