import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { getApp } from "../pages/App/Generator/utils";
import App from "../pages/App/Generator/App";
import { ChakraProvider, ThemeProvider } from "@chakra-ui/react";
import platformThemes from "./themes/generatorThemes"; // Importer les thèmes

function GeneratorRoutes() {
  const [data, setData] = useState(null);

  useEffect(() => {
    getApp()
      .then((fetchedData) => {
        setData(fetchedData);
      })
      .catch((error) => {
        console.error("Failed to fetch routes:", error);
      });
  }, []);

  if (!data) {
    return;
  }

  return (
    <ChakraProvider>
      <Routes>
        {data
          .filter((item) => item.platform === "MGEEK")
          .map((item, index) => {
            // Filtrer pour inclure uniquement "MGEEK"
            const theme =
              platformThemes[item.platform] || platformThemes.default;
            return (
              <Route
                key={index}
                path={item.route}
                element={
                  <ThemeProvider theme={theme}>
                    <App item={item} />
                  </ThemeProvider>
                }
              />
            );
          })}
      </Routes>
    </ChakraProvider>
  );
}

export default GeneratorRoutes;
