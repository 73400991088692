import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Image,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { startTimer } from "../store/index";

export default function FirstModal({ item, platform }) {
  const dispatch = useDispatch();

  // Configure useDisclosure pour ouvrir la modal automatiquement
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const handleCloseModal = () => {
    onClose();
    dispatch(startTimer());
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent w={"95%"} bg={"secondary"} p={5}>
          <ModalBody p={0}>
            <Image
              src={`https://buzzattitude.eu/data/generator/${platform}/${item.TimeId}/assets/logo.png`}
              alt="Logo"
              mb="4"
            />
            <Box
              as="pre"
              mb="0"
              whiteSpace={"pre-wrap"}
              textAlign={"center"}
              fontSize={"3vw"}
              fontFamily={"'Roboto', sans-serif"}
              color={"ternary"}>
              {item.introText}
            </Box>
          </ModalBody>
          <ModalFooter display={"flex"} justifyContent={"center"}>
            <Button
              color="ternary"
              bg={"primary"}
              mr={3}
              onClick={() => {
                handleCloseModal();
              }}>
              {item.introButtonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
