import axios from "axios";

// const urlLocal = "http://localhost:5500";
const urlLocal = "https://migros-server.onrender.com/api";

export async function SignIn(data) {
  try {
    const response = await axios.post(
      `${urlLocal}/api/stitch/memorygame/signin`,
      data
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
}
