import React, { useState, useEffect } from "react";
import { Box, Flex, IconButton, Text, VStack } from "@chakra-ui/react";
import { TfiTime, TfiGame, TfiMusicAlt, TfiHome } from "react-icons/tfi";
import { PiNewspaperLight } from "react-icons/pi";
import { useNavigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Momentums from "./pages/Momentums";
import Activations from "./pages/Activations";
import Playlist from "./pages/Playlist";
import News from "./pages/News";
import "./fonts/font.css";
import Spinner from "../App/components/Spinner";
import TranslationProvider from "./helper/TranslationProvider";
import ReactGA from "react-ga4";

const menuItems = [
  { name: "News", icon: PiNewspaperLight, component: <News /> },
  { name: "Sorties", icon: TfiTime, component: <Momentums /> },
  { name: "Home", icon: TfiHome, component: <Home /> },
  { name: "Activations", icon: TfiGame, component: <Activations /> },
  { name: "Playlist", icon: TfiMusicAlt, component: <Playlist /> },
];

const MobileMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeComponent, setActiveComponent] = useState(<Home />);
  const [IsLoading, setIsLoading] = useState("");



  useEffect(() => {
    ReactGA.initialize("G-XQX7P0YP3K"); // Remplacez par votre ID de mesure GA4
    ReactGA.send("pageview"); // Pour envoyer une vue de page
  }, []);

  useEffect(() => {
    const currentHash = location.hash.replace("#", "");
    if (currentHash.length === 0) {
      navigate("#Home");
    } else {
      const currentItem = menuItems.find((item) => item.name === currentHash);
      if (currentItem) {
        setActiveComponent(currentItem.component);
      }
    }
  }, [location, navigate]);

  const handleMenuClick = (item) => {
    // Si l'élément cliqué est déjà actif, retournez tôt
    if (activeComponent === item.component) return;

    // Définir l'état de chargement à true
    setIsLoading(true);

    // Utilisez un délai pour simuler le chargement, puis mettez à jour le composant actif
    setTimeout(() => {
      setActiveComponent(item.component);
      setIsLoading(false);
    }, 500);

    navigate(`#${item.name}`);
  };

  return (
    <TranslationProvider>
      <Box
        bg={
          "linear-gradient(225deg, rgba(55,121,188,1) 0%, rgba(118,73,141,1) 100%)"
        }
        height={"100vh"}
        overflow={"hidden"}
        maxW={"425px"}
        m={"auto"}>
        <Box h="calc(100vh - 63px)" overflowY="scroll">
          {IsLoading && (
            <Box
              h="100vh"
              w={"100%"}
              position={"absolute"}
              maxW={"425px"}
              m={"auto"}
              top={0}
              zIndex={99}
              bg={
                "linear-gradient(225deg, rgba(55,121,188,1) 0%, rgba(118,73,141,1) 100%)"
              }>
              <Spinner />
            </Box>
          )}
          {activeComponent}
        </Box>

        {/* Mobile Menu */}
        <Flex
          position="fixed"
          py={3}
          bottom={0}
          background={"rgb(109 79 147)"}
          left={0}
          right={0}
          maxW={"425px"}
          m={"auto"}
          _before={{
            content: "''",
            display: "block",
            width: "75%",
            height: "2px",
            background:
              "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(243,112,42,1) 68%, rgba(255,255,255,0) 100%);",
            position: "absolute",
            top: 0,
          }}
          justifyContent="space-around"
          alignItems="center"
          zIndex="sticky">
          {menuItems.map((item) => (
            <VStack
              key={item.name}
              spacing={1}
              filter={
                activeComponent === item.component
                  ? "drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7))"
                  : ""
              }>
              <IconButton
                aria-label={item.name}
                icon={<item.icon />}
                bg={"none"}
                h={"auto"}
                _hover={{ bg: "none" }}
                color={activeComponent === item.component ? "orange" : "white"}
                onClick={() => handleMenuClick(item)}
              />
              <Text
                fontSize="xs"
                m={0}
                fontFamily="'MigrosText', sans-serif"
                color={activeComponent === item.component ? "orange" : "white"}>
                {item.name}
              </Text>
            </VStack>
          ))}
        </Flex>
      </Box>
    </TranslationProvider>
  );
};

export default MobileMenu;
