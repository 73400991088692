import React, { ReactNode, useEffect } from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Image,
  Button,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { BiNews, BiUser, BiCalendarAlt, BiMusic, BiGame } from "react-icons/bi";
import { IconType } from "react-icons";
import { ReactText } from "react";
import News from "../pages/News";
import Home from "../pages/Home";
import Momentums from "../pages/Momentums";
import Activations from "../pages/Activations";
import Playlist from "../pages/Playlist";
import Logo from "../composants/Logo.svg";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { useContext } from "react";
import { useAuth } from "../helper/SsoContext";

interface LinkItemProps {
  name: string;
  icon: IconType;
  component: ReactNode;
  link: string;
}

const LinkItems: Array<LinkItemProps> = [
  { name: "Page D'accueil", icon: BiUser, component: <Home />, link: "Home" },
  { name: "Page News", icon: BiNews, component: <News />, link: "News" },
  {
    name: "Page Momentums",
    icon: BiCalendarAlt,
    component: <Momentums />,
    link: "Momentums",
  },
  {
    name: "Page Activations",
    icon: BiGame,
    component: <Activations />,
    link: "Activations",
  },
  {
    name: "Page Playlist",
    icon: BiMusic,
    component: <Playlist />,
    link: "Playlist",
  },
];

export default function SidebarWithHeader({
  children,
}: {
  children: ReactNode;
}) {
  const [activeComponent, setActiveComponent] = React.useState(<Home />);
  useEffect(() => {
    const savedPage = localStorage.getItem("activePage");
    if (savedPage) {
      const page = LinkItems.find((item) => item.link === savedPage);
      if (page) {
        setActiveComponent(page.component);
      }
    }
  }, []);

  useEffect(() => {
    const activePage = LinkItems.find(
      (item) => item.component === activeComponent
    );
    if (activePage) {
      localStorage.setItem("activePage", activePage.link);
    }
  }, [activeComponent]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        setActiveComponent={setActiveComponent}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            setActiveComponent={setActiveComponent}
          />
        </DrawerContent>
      </Drawer>

      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {activeComponent}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  setActiveComponent: (component: ReactNode) => void;
}

const SidebarContent = ({
  onClose,
  setActiveComponent,
  ...rest
}: SidebarProps) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
        <Image src={Logo} m="auto" w={"100px"} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          setActiveComponent={setActiveComponent}
          component={link.component}
          link={link.link} // Ajoutez cette ligne
          onClose={onClose}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  link: ReactText;
  setActiveComponent: (component: ReactNode) => void;
  component: ReactNode;
}
const NavItem = ({
  icon,
  children,
  link,
  setActiveComponent,
  component,
  onClose, // Ajoutez onClose ici
  ...rest
}: NavItemProps) => {
  return (
    <Link
      href={`#${link}`}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      onClick={() => {
        setActiveComponent(component);
        localStorage.setItem("activePage", link); // Enregistrer la page active
        onClose();
      }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  // const { logout } = useAuth();
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}>
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Image src={Logo} m="auto" w={"100px"} />

      {/* <Button fontSize="xs" m="0" color="gray.600" onClick={logout}>
        Déconnexion
      </Button> */}
    </Flex>
  );
};
