import React, { useState, useRef } from "react";
import {
  Box,
  Image,
  Select,
  Input,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import Checker from "../../../App/components/checker";
import Head from "../../../App/components/Head";
// import LogoBrand from "./img/logo.png";
import Title from "../../components/Title";
import case1 from "./img/case-1.jpg";
import case2 from "./img/case-2.jpg";
import case3 from "./img/case-3.jpg";
import GroguGif from "./img/goodanswer.gif";
import { Global, css } from "@emotion/react";
import LogoBrand from "./img/logo.png";

const PrimaryColor = "#0e1620";
const SecondaryColor = "#f7931e";

export default function Tetris() {
  const [hideSignIn, setHideSignIn] = useState(false);
  const [disabledInput, setDisabledInput] = useState(true);
  const [HideForm, setHideForm] = useState(false);
  const [GifGoodAnswer, setGifGoodAnswer] = useState(false);

  const RepCase1 = useRef();
  const RepCase2 = useRef();
  const RepCase3 = useRef();
  const InputController = useRef();

  const handleChange = () => {
    if (
      RepCase1.current.value === "Bo-Katan" &&
      RepCase2.current.value === "Mandalore" &&
      RepCase3.current.value === "Pour le protéger"
    ) {
      setDisabledInput(false);
      setGifGoodAnswer(true);
    }
  };

  const HideFormEnd = () => {
    setHideForm(true);
  };

  return (
    <>
      <Global
        styles={css`
          select option {
            background-color: #4080c5 !important;
            padding-left: 0 !important;
            margin-left: -10px !important;
          }
        `}
      />
      <Head title="Missing the child" />
      {hideSignIn ? (
        ""
      ) : (
        <Box
          maxW="425px"
          w="100%"
          m="0 auto"
          position="relative"
          overflow="hidden"
          minH="100vh"
          backgroundImage=" linear-gradient(225deg, rgba(55,121,188,1) 0%, rgba(118,73,141,1) 100%);"
          color="white"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box textAlign="center" pt="30px" pb="20px">
            <Image src={LogoBrand} alt="logo" />
          </Box>
          <Title text={"Grogu a disparu !"} />
          <Text opacity="0.7" w="95%" fontSize="13px" m="10px auto">
            Grogu a encore disparu ! Étant donné que Mando est injoignable, nous
            faisons appel à vous, le-la deuxième meilleur-e chasseur-se de
            primes de la galaxie ! <br />
            Votre mission : à l'aide des indices ci-dessous, retrouvez où se
            cache Grogu et qui l'a emmené !
          </Text>
          <Text opacity="0.7" w="95%" fontSize="13px">
            Alignez les bonnes propositions et validez votre mission.
          </Text>
          {GifGoodAnswer ? (
            <>
              <Box
                maxW="420px"
                borderRadius="10px"
                overflow="hidden"
                boxShadow="0px 0px 20px rgb(0 0 0)"
              >
                <Image src={GroguGif} alt="" />
              </Box>
              {HideForm ? <Checker color={SecondaryColor} /> : ""}
            </>
          ) : (
            <Flex
              className="IMGset"
              justifyContent="space-between"
              width={"95%"}
            >
              <Box w="31%" mt="15px">
                <Box overflow="hidden" borderRadius="10px">
                  <Image src={case1} alt="" />
                </Box>
                <Select
                  ref={RepCase1}
                  onChange={handleChange}
                  mt="5px"
                  bgColor="transparent"
                  color="white"
                  pl={0}
                  size={"sm"}
                  borderRadius={"md"}
                >
                  <option value="">--</option>
                  <option value="Bo-Katan">Bo-Katan</option>
                  <option value="Ahsoka Tano">Ahsoka Tano </option>
                  <option value="Mok Shaiz">Mok Shaiz</option>
                </Select>
              </Box>
              <Box w="31%" mt="15px">
                <Box overflow="hidden" borderRadius="10px">
                  <Image src={case2} alt="" />
                </Box>
                <Select
                  ref={RepCase2}
                  onChange={handleChange}
                  mt="5px"
                  bgColor="transparent"
                  color="white"
                  pl={0}
                  size={"sm"}
                  borderRadius={"md"}
                >
                  <option value="">--</option>
                  <option value="Endor">Endor </option>
                  <option value="Mandalore">Mandalore</option>
                  <option value="Tatooine">Tatooine </option>
                </Select>
              </Box>
              <Box w="31%" mt="15px">
                <Box overflow="hidden" borderRadius="10px">
                  <Image src={case3} alt="" />
                </Box>
                <Select
                  ref={RepCase3}
                  onChange={handleChange}
                  mt="5px"
                  bgColor="transparent"
                  color="white"
                  size={"sm"}
                  borderRadius={"md"}
                >
                  <option value="">--</option>
                  <option value="Pour l'argent">Pour l'argent</option>
                  <option value="Pour le protéger">Pour le protéger</option>
                  <option value="Pour l'entrainer">Pour l'entrainer </option>
                </Select>
              </Box>
            </Flex>
          )}
        </Box>
      )}
    </>
  );
}
