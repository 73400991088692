import React from "react";
import { Text } from "@chakra-ui/react";

export default function Intro({ py, text }) {
  return (
    <Text fontSize={"11px"} color={"white"} py={py} m={0} textAlign={"center"}>
      {text}
    </Text>
  );
}
