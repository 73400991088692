import React, { useEffect, useState } from "react";
import {
  Box,
  Image,
  Flex,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Carousel from "../components/Carousel";
import Header from "../components/Header";
import Intro from "../components/Intro";
import Title from "../components/Title";
import TranslationContext from "../helper/TranslationContext";
import { useContext } from "react";
import Spinner from "../components/Spinner";

export default function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const translations = useContext(TranslationContext);
  const [homeTranslations, setHomeTranslations] = useState({});
  const [IsLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (translations[0]) {
      setIsLoading(false);
      setHomeTranslations(translations[0]?.fr?.home);
    }
  }, [translations]);

  return (
    <>
      {IsLoading ? (
        <Box
          h="100vh"
          w={"100%"}
          position={"absolute"}
          maxW={"425px"}
          m={"auto"}
          top={0}
          zIndex={99}
          bg={
            "linear-gradient(225deg, rgba(55,121,188,1) 0%, rgba(118,73,141,1) 100%)"
          }>
          <Spinner />
        </Box>
      ) : (
        <>
          <Header p="4" text={homeTranslations?.header?.text} />
          <Box p="2">
            <Intro py="1" text={homeTranslations?.intro?.text} />
            <Box>
              <Title text={homeTranslations?.promotions?.title} py="2" />
              <Carousel images={homeTranslations?.promotions?.images} />
            </Box>

            <Box mt={4}>
              <Flex justify={"space-between"} gap={2}>
                <Box
                  w={"50%"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"flex-end"}>
                  {/* <Title text={homeTranslations?.findMgeek?.text} py="2" /> */}
                  <Link onClick={onOpen}>
                    <Image
                      src={homeTranslations?.findMgeek?.image}
                      borderRadius={"md"}
                      boxShadow="1px 1px 5px rgba(0, 0, 0, 0.5)"
                    />
                  </Link>
                </Box>
                <Box
                  w={"50%"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"flex-end"}>
                  {/* <Title text={homeTranslations?.instagram?.text} py="2" /> */}
                  <Link
                    href={homeTranslations?.instagram?.link}
                    target="_blank">
                    <Image
                      src={homeTranslations?.instagram?.image}
                      borderRadius={"md"}
                      boxShadow="1px 1px 5px rgba(0, 0, 0, 0.5)"
                    />
                  </Link>
                </Box>
              </Flex>
            </Box>
          </Box>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent w={"95%"} overflow={"hidden"}>
              <ModalHeader>
                <Title
                  text={homeTranslations?.findMgeek?.text}
                  py="0"
                  color="blue"
                />
              </ModalHeader>
              <ModalCloseButton />
              <Box w={"100%"} overflow={"hidden"}>
                <Box
                  as="iframe"
                  src={homeTranslations?.findMgeek?.map.url}
                  height="100%"
                  border="0"
                  w={"100%"}
                  h={"60vh"}
                  marginTop="-59px"
                />
              </Box>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}
