import React, { useEffect, useState } from "react";
import { Box, Heading, Image } from "@chakra-ui/react";
import TranslationContext from "../helper/TranslationContext";
import { useContext } from "react";

export default function Header({ p, noLogo, text, bgImage }) {
  const translations = useContext(TranslationContext);
  const [IsLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (translations[0]?.LogoUrl) {
      setIsLoading(false)
    }else  {
      setIsLoading(true);
    }
  }, [translations]);


  return (
    
      !IsLoading && <>
      <Box
        px={p}
        pt={4}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        position="relative" // Position relative pour le conteneur
        _before={{
          content: '""', // Contenu vide pour le pseudo-élément
          position: "absolute", // Position absolue pour le pseudo-élément
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          left: 0,
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "100%",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          opacity: 0.3,
        }}
      >
        {noLogo ? (
          ""
        ) : (
          <Image
            src={translations[0]?.LogoUrl}
            alt="image"
            w={"80px"}
            mb={2}
            position={"relative"}
            zIndex={1}
          />
        )}
        <Heading
          color={"white"}
          as={"h1"}
          m={0}
          textAlign="center"
          position={"relative"}
          zIndex={1}
          fontSize={"16px"}
          textTransform={"uppercase"}
          lineHeight={1.2}
        >
          {text}
        </Heading>
      </Box>
    </>
    
    
  );
}
