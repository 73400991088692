import axios from "axios";

// const apiUrl = "https://r6g.herokuapp.com";
// const apiUrl = "http://localhost:5500";
const apiUrl = "https://migros-server.onrender.com";

export const sendDataToBackend = async (data) => {
  try {
    const response = await axios.post(
      `${apiUrl}/migros/formulaire-visite/send-email`,
      data
    );
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

export const sendImagesToBackend = async (images) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append(`images`, images[i]);
    }
    const response = await axios.post(
      `${apiUrl}/migros/image-commerciaux/upload-ftp`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.error("Error sending images:", error);
  }
};
