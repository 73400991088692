import React from "react";
import AdminRoutes from "./routes/Routes";
import GeneratorRoutes from "./routes/GeneratorRoutes";

function App() {
  // This arrangement can be altered based on how we want the date's format to appear.
  return (
    <>
      <AdminRoutes />
      <GeneratorRoutes />
    </>
  );
}

export default App;
