import React from "react";
import { Flex, Stack, Button, Heading, Image } from "@chakra-ui/react";

const Login = () => {
  const handleLogin = () => {
    // Remplacer par l'URL de votre backend
    window.location.href =
      "https://migros-server.onrender.com/auth/microsoft?prompt=login";
    // window.location.href = "http://localhost:5500/auth/microsoft?prompt=login";
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Image
            src="https://www.buzzattitude.eu/data/migros/app/logo-mgeek.png"
            w={"100px"}
          />
          <Heading fontSize={"4xl"}>Connectez-vous</Heading>
        </Stack>
        <Button
          bg={"blue"}
          color={"white"}
          _hover={{
            bg: "blue.500",
          }}
          onClick={handleLogin}>
          Se connecter via SSO{" "}
        </Button>
      </Stack>
    </Flex>
  );
};

export default Login;
