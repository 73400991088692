import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import Redirect

export const AuthContext = React.createContext({
  isLoggedIn: {},
  setIsLoggedIn: () => {},
  username: null,
  setUsername: () => {},
});

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(
    localStorage.getItem("isLoggedIn") === "false"
  );
  const [username, setUsername] = React.useState(
    localStorage.getItem("username") || null
  );

  const navigate = useNavigate();

  const logout = () => {
    localStorage.setItem("isLoggedIn", "false");
    navigate("/admin/login");
    setUsername(null);
    localStorage.clear();
  };

  // Mettre à jour le stockage local lorsque `isLoggedIn` ou `username` change
  useEffect(() => {
    localStorage.setItem("username", username);
  }, [isLoggedIn, username]);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, setIsLoggedIn, username, setUsername, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
