import React, { useState } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import SidebarWithHeader from "../composants/SideBarWithHeader.tsx";
import { AuthProvider } from "../helper/SsoContext.js";

export default function App() {
  return (
    <AuthProvider>
      <ChakraProvider>
        <SidebarWithHeader />
      </ChakraProvider>
    </AuthProvider>
  );
}
