import React, { useEffect, useState, useContext } from "react";
import { Box, Image, Select, Text } from "@chakra-ui/react";
import { Global, css } from "@emotion/react";
import Header from "../components/Header";
import moment from "moment";
import useInfiniteScroll from "react-infinite-scroll-hook";
import TranslationContext from "../helper/TranslationContext";
import { getMomentums } from "../helper/utils";

moment.locale("fr");

export default function Momentums() {
  const [items, setItems] = useState([]);
  const [itemsToShow, setItemsToShow] = useState([]);
  const [page, setPage] = useState(1);

  const translations = useContext(TranslationContext);
  const momentumTranslations = translations[0]?.fr?.momentum;
  const filterOptions = momentumTranslations?.filterOptions;

  const fetchItems = async () => {
    try {
      const allItems = await getMomentums();

      // Filtrer les éléments pour ne conserver que ceux dont la date est postérieure à la date actuelle
      const futureItems = allItems.filter((item) =>
        moment(item.date).isSameOrAfter(moment())
      );

      // Trier les éléments par date
      const sortedItems = futureItems.sort((a, b) =>
        moment(a.date).isBefore(b.date) ? -1 : 1
      );

      setItems(sortedItems); // Mettez à jour les éléments avec les éléments triés
      setItemsToShow(sortedItems);
    } catch (error) {
      console.error("Erreur lors de la récupération des éléments:", error);
    }
  };

  const filter = (e) => {
    const value = e.target.value;
    if (value === "Voir tout") {
      setItemsToShow(items);
    } else {
      const filteredData = items.filter((product) =>
        product.tag.includes(value)
      );
      setItemsToShow(filteredData);
    }
  };

  const hasNextPage = itemsToShow.length < items.length;
  const loadMore = () => {
    const newItems = itemsToShow.slice(0, 6 + page * 4);
    setItemsToShow(newItems);
    setPage(page + 1);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage,
    onLoadMore: loadMore,
  });

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <Header
        p="4"
        text={momentumTranslations?.header?.text}
        bgImage={momentumTranslations?.header?.bgImage}
      />
      <Box p="2">
        <Select
          size="sm"
          color="white"
          bg="transparent"
          borderRadius="md"
          onChange={filter}
        >
          {filterOptions?.map((option, index) => (
            <option key={index}>{option}</option>
          ))}
        </Select>
        <Box mt={6}>
          {itemsToShow.map(({ index, date, image, nameFR }) => (
            <Box
              display={"flex"}
              gap={4}
              justifyContent={"space-between"}
              key={index}
            >
              <Text
                color={"white"}
                m={0}
                fontWeight={"bold"}
                textAlign={"right"}
                pr={4}
                minW={"60px"}
                position="relative"
                _before={{
                  content: '""',
                  position: "absolute",
                  right: "0",
                  top: "0px",
                  width: "10px",
                  height: "10px",
                  backgroundColor: "orange",
                  borderRadius: "50%",
                }}
                _after={{
                  content: '""',
                  position: "absolute",
                  right: "4px", // Centrer la barre par rapport au cercle
                  top: "0",
                  width: "1px",
                  height: "100%",
                  backgroundColor: "orange",
                  display: "block",
                }}
              >
                <Box>{moment(date).format("MMM").toUpperCase()}</Box>

                {moment(date).format("DD").toUpperCase()}
              </Text>
              <Box
                position={"relative"}
                overflow={"hidden"}
                borderRadius={"md"}
                w={"100%"}
                mb={4}
                boxShadow="1px 1px 5px rgba(0, 0, 0, 0.5)"
              >
                <Text
                  color={"white"}
                  m={0}
                  position={"absolute"}
                  bottom={0}
                  pl={2}
                  w={"100%"}
                  background={"rgba(241, 103, 36, 0.5)"}
                >
                  {nameFR}
                </Text>
                <Image src={image} />
              </Box>
            </Box>
          ))}
        </Box>
        <Box bg="red" ref={sentryRef}></Box>
      </Box>
      <Global
        styles={css`
          select option {
            background-color: #4080c5 !important;
          }
        `}
      />
    </>
  );
}
