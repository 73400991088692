import React from "react";
import { Box, Image } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function ImageCarousel({ images }) {
  return (
    <Box boxShadow="1px 1px 5px rgba(0, 0, 0, 0.5)" borderRadius={"md"}>
      <style>
        {`
        .control-dots{
          margin:0 !important;
        }
          .carousel .control-dots .dot {
            background: white !important;
            margin:0 2px ;
          }
          .carousel-status{
            display:none;
          }
        `}{" "}
      </style>

      <Carousel showThumbs={false}>
        {/* <Box as="a" href="/games/tamagotchi" display={"block"}>
          <Image src={"https://www.buzzattitude.eu/data/migros/app/animations/tamagotchi-thumb.png"} alt={`Tamagotchi`} borderRadius={"md"} />
        </Box> */}
        {images?.map((image, index) => (
          <div key={index}>
            <Image src={image} alt={`Slide ${index}`} borderRadius={"md"} />
          </div>
        ))}
      </Carousel>
    </Box>
  );
}
