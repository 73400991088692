import React from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import GameMemory from "./component/App";
import Title from "../../components/Title";
import LogoBrand from "./img/logo.png";

const PrimaryColor = "#0e1620";

export default function MemoryGameApp(props) {
  return (
    <>
      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        backgroundImage={
          "linear-gradient(225deg, rgba(55,121,188,1) 0%, rgba(118,73,141,1) 100%);"
        }
        height={"100vh"}
        textAlign={"center"}
      >
        <Box textAlign="center" pt="30px" pb="20px">
          <Image src={LogoBrand} alt="logo" />
        </Box>
        <Title color={"white"} text="Choisissez une carte pour commencer." />
        <Text fontSize="15px" color={"white"} textAlign="center" mb={4}>
          Réunissez toutes les paires pour remporter la partie.
        </Text>
        <Box>
          <GameMemory />
        </Box>
      </Flex>
    </>
  );
}
