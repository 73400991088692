import React, { useContext } from "react";
import { Box } from "@chakra-ui/react";
import Header from "../components/Header";
import TranslationContext from "../helper/TranslationContext";

export default function Playlist() {
  const translations = useContext(TranslationContext);
  const playlistTranslations = translations[0]?.fr?.playlist;
  const playlists = playlistTranslations?.playlists;

  return (
    <>
      <Header p="4" text={playlistTranslations?.header?.text} />
      <Box p="2">
        {playlists?.map((playlist, id) => (
          <Box
            mb={2}
            boxShadow="1px 1px 5px rgba(0, 0, 0, 0.5)"
            borderRadius={"20px"}
          >
            <iframe
              title="playlist"
              key={id}
              src={playlist}
              height="152"
              width={"100%"}
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            ></iframe>
          </Box>
        ))}
      </Box>
    </>
  );
}
