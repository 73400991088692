import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Head from "../../../App/components/Head";
import "./styles/styles.scss";
import TetrisPack from "react-tetris";
import LogoBrand from "./img/logo.png";
import Background from "./img/bg.jpg";

import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import Title from "../../components/Title";

import {
  mdiChevronRight,
  mdiChevronLeft,
  mdiChevronDown,
  mdiReload,
  mdiPause,
} from "@mdi/js";
import Icon from "@mdi/react";
import { useRef } from "react";

const PrimaryColor = "#0e1620";
const SecondaryColor = "#f7931e";
const TernaryColor = "#ffffff";

const Section = styled.section`
  background-image: linear-gradient(
    225deg,
    rgba(55, 121, 188, 1) 0%,
    rgba(118, 73, 141, 1) 100%
  );
  width: 100%;
  min-height: 100vh;
`;

const Signup = styled.div`
  display: flex;
  align-items: center;
  form {
    background: ${PrimaryColor};
    color: #fff;
    width: 90%;
    max-width: 425px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    border-radius: 10px;

    font-family: "Open Sans", sans-serif;
    input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      outline: none;
      padding: 0;
      margin: 0 auto;
      display: block;
      width: 100%;
      max-width: 280px;
      background-color: transparent;
      color: #fff !important;
      border-bottom: 3px solid #ff9f00 !important;
      text-align: center;
    }
    label {
      text-align: center;
      margin-top: 5px;
      display: block;
    }
    button {
      margin: 20px auto 0 auto;
      display: block;
      border: none;
      color: #fff;
      font-size: 20px;
      background-color: ${SecondaryColor};
      font-family: "Open Sans", sans-serif;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
      padding: 10px 20px;
      border-radius: 10px;
    }
  }
`;

const Box = styled.div`
  display: flex;
  background: #9ead86;
  border: 5px solid;
  background: #9ead86;
  border-color: #b57205 #edc88c #fae36c #b57205;
  position: relative;
  padding: 7px;
  justify-content: space-around;
  margin: 0 auto;
`;

const Table = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "digital-7";
  color: black;
  text-align: left;
  font-weight: 500;
  p {
    font-size: 22px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: right;
  }
`;

const Screen = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Popup = styled.div`
  background: ${PrimaryColor};
  position: absolute;
  z-index: 99;
  text-transform: uppercase;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  text-align: center;
  width: 95%;
  border-radius: 10px;
  top: 50%;
  box-shadow: rgb(105 109 112) -3px -3px 2px inset;
  p {
    text-transform: none;
  }
  button {
    color: #fff;
    background-color: ${SecondaryColor};
    padding: 5px 20px;
    border-radius: 10px;
  }
  &.width {
    max-width: 250px;
  }
`;

const Pause = styled.div`
  border-radius: 10px;
  margin-left: 30px;
  margin-top: 20px;
  margin: 0 auto;
  width: 40px;
  box-shadow: #f5b263 -3px -3px 2px inset;
  background: ${SecondaryColor};
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Rotate = styled.div`
  border-radius: 10px;
  margin-left: 30px;
  width: 100px;
  box-shadow: #f5b263 -3px -3px 2px inset;

  background: ${SecondaryColor};
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Controller = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;

  svg {
    display: block;
    margin: 0 auto;
  }

  .bottom {
    width: 50%;
    height: 50px;
    .btnc {
      margin: 0 auto;
    }
  }
  .middle {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
  }
  .btnc {
    background: ${SecondaryColor};
    width: 50px;
    height: 50px;
    display: block;
    box-shadow: #f5b263 -3px -3px 2px inset;
    border-radius: 10px;
  }
`;

export default function Tetris() {
  const [hideSignIn, SetHideSignIn] = useState(false);
  const startGame = useRef();

  const pageChange = () => {
    SetHideSignIn(true);
  };

  const sendDb = () => {
    // Enregistrer info en DB
  };

  const [showModal, setShowModal] = useState(false);
  const [reloadKey, setReloadKey] = useState(0);

  const handleRestart = () => {
    setReloadKey(reloadKey + 1);
    setShowModal(false);
  };

  return (
    <>
      <Head title="Tetris" OpenSans />

      <Section key={reloadKey}>
        <div className="deco left"></div>

        <Screen>
          <TetrisPack
            keyboardControls={{
              // Default values shown here. These will be used if no
              // `keyboardControls` prop is provided.
              down: "MOVE_DOWN",
              left: "MOVE_LEFT",
              right: "MOVE_RIGHT",
              space: "HARD_DROP",
              z: "FLIP_COUNTERCLOCKWISE",
              x: "FLIP_CLOCKWISE",
              up: "FLIP_CLOCKWISE",
              p: "TOGGLE_PAUSE",
              c: "HOLD",
              shift: "HOLD",
            }}>
            {({
              HeldPiece,
              Gameboard,
              PieceQueue,
              points,
              linesCleared,
              state,
              controller,
            }) => (
              <div>
                <Box className="remover">
                  <Gameboard />
                  <Table>
                    <p>
                      Points: <span>{points}</span>
                    </p>
                    <p>
                      Lignes: <span>{linesCleared}</span>
                    </p>
                    <div>
                      <p>Suivant:</p>
                      <span>
                        <PieceQueue />
                      </span>
                    </div>
                  </Table>
                </Box>
                {state === "LOST" && (
                  <Modal isOpen={true} onClose={() => setShowModal(false)}>
                    <ModalOverlay />
                    <ModalContent
                      backgroundColor={PrimaryColor}
                      w={"95%"}
                      p={4}
                      alignItems={"center"}
                      m={"auto"}>
                      <ModalHeader color={"white"} textAlign="center" pt={6}>
                        <Title
                          color={"white"}
                          text="Merci d'avoir participé !"
                        />
                      </ModalHeader>
                      <ModalBody textAlign="center" p={0}>
                        <Button
                          color="white"
                          backgroundColor={"orange"}
                          borderRadius="10px"
                          onClick={() => handleRestart()}>
                          Rejouer
                        </Button>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                )}

                {window.addEventListener("load", (event) => {
                  controller.pause();

                  startGame.current.addEventListener("click", (event) => {
                    controller.restart();
                  });
                })}

                {state === "PAUSED" && (
                  <Modal isOpen={true} onClose={() => setShowModal(false)}>
                    <ModalOverlay />
                    <ModalContent
                      backgroundColor={PrimaryColor}
                      w={"95%"}
                      p={4}
                      alignItems={"center"}
                      m={"auto"}>
                      <ModalBody textAlign="center" p={0}>
                        <Title color={"white"} text="Le jeu est en pause" />
                        <Button
                          color="white"
                          backgroundColor={"orange"}
                          borderRadius="10px"
                          onClick={controller.resume}
                          mt={2}>
                          Reprendre
                        </Button>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                )}
                {state === "PAUSE"}
                <div className="linedeco">
                  <Pause onClick={controller.pause}>
                    <Icon path={mdiPause} size={1} />
                  </Pause>
                </div>
                <Flex>
                  <Rotate onClick={controller.flipClockwise}>
                    <Icon path={mdiReload} size={2} />
                  </Rotate>

                  <Controller>
                    <div className="middle">
                      <span className="btnc btnl" onClick={controller.moveLeft}>
                        <Icon path={mdiChevronLeft} size={2} />
                      </span>
                      <span
                        className="btnc btnr"
                        onClick={controller.moveRight}>
                        <Icon path={mdiChevronRight} size={2} />
                      </span>
                    </div>
                    <div className="bottom">
                      <span className="btnc" onClick={controller.moveDown}>
                        <Icon path={mdiChevronDown} size={2} />
                      </span>
                    </div>
                  </Controller>
                </Flex>
              </div>
            )}
          </TetrisPack>
        </Screen>
      </Section>
    </>
  );
}
