import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export default function Player({
  name,
  borderColor,
  background,
  CorrectAnswerCpt,
  player,
  NbrQuestions,
  start,
}) {
  const [cpt, setCpt] = useState(0);

  useEffect(() => {
    if (player === "player") {
      setCpt(CorrectAnswerCpt * (100 / (NbrQuestions - 2)));
    } else if (player === "bot" && start === true) {
      // Pour les bots, mettez en place un intervalle pour avancer toutes les 15 secondes
      const interval = setInterval(() => {
        updateBotScore();
      }, 4000);

      // Nettoyez l'intervalle lorsque le composant est démonté ou lorsque le bot atteint la fin
      return () => clearInterval(interval);
    }
  }, [CorrectAnswerCpt, player, NbrQuestions, cpt, start]);

  const updateBotScore = () => {
    const probabilityOfCorrectAnswerRandom = Math.random() * (0.7 - 1) + 0.7; // Génère un nombre aléatoire entre 0.6 et 1

    const probabilityOfCorrectAnswer = probabilityOfCorrectAnswerRandom;

    if (Math.random() < probabilityOfCorrectAnswer && cpt < 100) {
      setCpt((prevCpt) => Math.min(prevCpt + 100 / NbrQuestions, 100)); // S'assurer que cpt ne dépasse pas 100
    }
  };

  return (
    <Box>
      <Box
        background={"primary"}
        boxShadow={"0px 2px 3px rgba(0, 0, 0, 0.5)"}
        width={"100%"}
        borderRadius={"full"}
        height={"7px"}
        position={"relative"}>
        <Box
          background={background}
          border={"1px solid"}
          borderColor={borderColor}
          borderRadius={"full"}
          height={"20px"}
          position={"absolute"}
          left={cpt > 100 ? "100%" : `${cpt}%`}
          top={"-100%"}
          transform={cpt > 100 ? `translateX(-100%)` : `translateX(-${cpt}%)`}
          width={"20px"}></Box>
      </Box>
      <Text variant={600} mt={2} mb={2} fontSize={"3vw"}>
        {name}
      </Text>
    </Box>
  );
}
