import axios from "axios";
const dbURL = "https://migros-server.onrender.com";

export const postData = async (data) => {
  try {
    const response = await axios.post(
      `${dbURL}/api/maythefourth2024/signin`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de l'ajout de la nouvelle:",
      error
    );
    throw error;
  }
};
