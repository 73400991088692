import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Text,
  Link,
  VStack,
  Icon,
} from "@chakra-ui/react";
import { FaFacebook, FaInstagram } from "react-icons/fa"; // Importez les icônes Facebook et Instagram
import Checker from "../../components/checker";
import { useTheme } from "@chakra-ui/react";

export default function Formulaire({
  form,
  btnForm,
  feedbackText,
  socialLinks,
  onChange,
  formUrl,
}) {
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const theme = useTheme();

  const handleChange = (id, value) => {
    setInputs((prev) => ({ ...prev, [id]: value }));
    if (errors[id]) {
      setErrors((prev) => ({ ...prev, [id]: null }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    let newErrors = {};

    form.forEach((field) => {
      if (field.required && !inputs[field._id]) {
        valid = false;
        newErrors[field._id] = "Ce champ est requis";
      }
    });

    if (valid) {
      setSubmitted(true);
      // Envoie les données au script Google Apps
      try {
        const response = await fetch(formUrl, {
          method: "POST",
          mode: "no-cors", // Note: no-cors mode for debugging only; not recommended for production
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(inputs), // Assurez-vous que `inputs` contient les données à envoyer
        });
        const result = await response.text();
        console.log(result);
      } catch (error) {
        console.error("Error posting data", error);
      }
    } else {
      setErrors(newErrors);
    }
  };

  if (submitted) {
    return (
      <Box p={5} textAlign="center">
        <Box
          as="pre"
          mb="0"
          whiteSpace={"pre-wrap"}
          textAlign={"center"}
          fontSize={"3vw"}
          fontFamily={"'Roboto', sans-serif"}
          color={"ternary"}>
          {feedbackText}
        </Box>
        <Checker color={theme.colors.primary} />
        <Box display={"flex"} justifyContent={"center"} mt={5} gap={5}>
          <Link
            href={socialLinks.facebook}
            isExternal
            color={"#ternary"}
            _hover={{ color: "ternary" }}>
            <Icon as={FaFacebook} w={10} h={10} mr={2} />
          </Link>

          <Link
            href={socialLinks.instagram}
            isExternal
            color={"ternary"}
            _hover={{ color: "ternary" }}>
            <Icon as={FaInstagram} w={10} h={10} mr={2} />
          </Link>
        </Box>
      </Box>
    );
  }

  return (
    <VStack as="form" onSubmit={handleSubmit} spacing={4}>
      {form.map((field) => (
        <FormControl key={field._id} isInvalid={!!errors[field._id]}>
          <FormLabel>{field.name}</FormLabel>
          <Input
            _active={{ borderColor: "primary" }}
            _hover={{ borderColor: "primary" }}
            _focus={{ borderColor: "primary" }}
            type="text"
            value={inputs[field._id] || ""}
            onChange={(e) => handleChange(field._id, e.target.value)}
            isRequired={field.required}
          />
          {errors[field._id] && (
            <FormErrorMessage>{errors[field._id]}</FormErrorMessage>
          )}
        </FormControl>
      ))}
      <Box display={"flex"} justifyContent={"center"} gap={2}>
        <Button color="ternary" bg={"primary"} type="submit">
          {btnForm}
        </Button>
        {/* <Button
          onClick={() => onChange()} // Modifiez cela pour utiliser une fonction fléchée
          color="primary"
          bg={"ternary"}
          className="button">
          Rejouer
        </Button> */}
      </Box>
    </VStack>
  );
}
