import axios from "axios";

// const dbURL = "http://localhost:5500/api";
// const dbURLAuth = "http://localhost:5500/auth";
// const dbUploadURL = "http://localhost:5500";
const dbURL = "https://migros-server.onrender.com/api";
// const dbURLAuth = "https://migros-server.onrender.com/auth";
// const dbUploadURL = "https://migros-server.onrender.com";

export const SavedUser = async (data) => {
  try {
    const response = await axios.post(`${dbURL}/app/tamagotchi`, data); // Remplacez par l'URL correcte
    return response.data;
  } catch (error) {
    console.error("An error occurred during  verification:", error);
    throw error;
  }
};
