import React from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";

const Login = () => {
  const { instance, accounts } = useMsal();
  console.log(accounts);
  const handleLogin = () => {
    // Remplacer par l'URL de votre backend
    window.location.href =
      "https://migros-server.onrender.com/auth/microsoft?prompt=login";
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Image
            src="https://www.buzzattitude.eu/data/migros/app/logo-mgeek.png"
            w={"100px"}
          />
          <Heading fontSize={"4xl"}>Connectez-vous</Heading>
        </Stack>
        <Button
          bg={"blue"}
          color={"white"}
          _hover={{
            bg: "blue.500",
          }}
          onClick={handleLogin}>
          Se connecter via SSO{" "}
        </Button>
      </Stack>
    </Flex>
  );
};

export default Login;
