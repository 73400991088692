import React, { useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
} from "@chakra-ui/react";
import GameMemory from "./component/App";
import Title from "../../components/Title";
import LogoBrand from "./img/logo.png";
import bg from "./img/bg.png";
import concoursLogo from "./img/logo.png"; // Assurez-vous d'importer votre image de logo de concours
import copyright from "./img/copyright.png";

const PrimaryColor = "#0e1620";

export default function MemoryGameApp(props) {
  const [isOpen, setIsOpen] = useState(true);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        backgroundImage={bg}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        height={"100vh"}
        textAlign={"center"}>
        <Box textAlign="center" pt="30px" pb="20px">
          <Image src={LogoBrand} alt="logo" />
        </Box>
        <Title color={"white"} text="Choisissez une carte pour commencer." />
        <Text fontSize="15px" color={"white"} textAlign="center" mb={4}>
          Réunissez toutes les paires pour remporter la partie.
        </Text>

        <Box>
          <GameMemory />
        </Box>
        <Image
          w={"60px"}
          zIndex={0}
          position={"absolute"}
          bottom={"10px"}
          right={"10px"}
          src={copyright}
          alt="logo"
        />
      </Flex>

      <Modal isOpen={isOpen} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent
          backgroundColor={"#3f7cc3"}
          w={"95%"}
          m={"auto"}
          border={"1px solid"}
          borderColor={"white"}>
          <ModalHeader>
            <Image src={concoursLogo} alt="Concours Logo" mx="auto" />
          </ModalHeader>
          <ModalBody textAlign={"center"} p={"5px 10px"}>
            <Heading fontSize={"20px"} color={"white"}>
              Concours Stitch Day
            </Heading>
            <Text fontSize="15px" color={"white"} m={"20px auto"}>
              Retrouve les paires et tente de remporter un pack de produits
              dérivés de l'expérience la plus célèbre de Disney !
            </Text>
            <Button
              m={"0 auto 20px auto"}
              size="sm"
              p={"20px"}
              colorScheme="blue"
              onClick={closeModal}
              bg={"white"}
              color={"#3f7cc3"}>
              Fermer
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
