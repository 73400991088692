import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Link,
} from "@chakra-ui/react";

function NewUserModal({ isOpen, onClose, ModalData }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"95%"} m={"auto"} bg={"#3b347f"} color={"white"}>
        <ModalHeader textAlign={"center"}>{ModalData.title}</ModalHeader>
        <ModalBody textAlign={"center"}>
          {ModalData.text && <Text mb={"10px"}>{ModalData.text}</Text>}
          <Link
            textDecoration={"none"}
            _hover={{ textDecoration: "none" }}
            target="_blank"
            borderBottom={"1px solid white"}
            href={"https://mgeek.ch/reglement/reglement-tamagotchi.pdf"}>
            Voir le règlement
          </Link>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            bg={"orange_second"}
            display={"block"}
            m={"auto"}
            color={"white"}
            borderBottom={"2px solid"}
            borderColor={" orange_black"}
            _hover={{
              bg: "orange_second",
              border: "none",
            }}>
            {ModalData.btn}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default NewUserModal;
