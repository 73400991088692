import React from "react";
import CookieConsent from "react-cookie-consent";
// import Cookies from "js-cookie";

const App = ({ buttonText, cookieName, style, buttonStyle }) => {
  // Cookies.remove("myAppNameCookieConsent");

  return (
    <div>
      {/* Votre contenu d'application ici */}

      <CookieConsent
        location="bottom"
        buttonText={buttonText}
        cookieName={cookieName}
        style={style}
        buttonStyle={buttonStyle}
      >
        Ce site utilise des cookies uniquement pour améliorer votre expérience.
        En utilisant notre site, vous acceptez notre utilisation des cookies.
      </CookieConsent>
    </div>
  );
};

export default App;
