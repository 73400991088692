import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  //   useEffect(() => {
  //     // Vérifiez l'authentification de l'utilisateur lors du chargement de l'application
  //     axios
  //       .get("https://2ff3-87-67-150-183.ngrok-free.app/api/auth/user") // Ajustez l'URL en fonction de votre backend
  //       .then((response) => {
  //         if (response.data.isAuthenticated) {
  //           setUser(response.data.user);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Erreur d'authentification", error);
  //       });
  //   }, []);

  const login = () => {
    window.location.href =
      "https://2ff3-87-67-150-183.ngrok-free.app/auth/microsoft?prompt=login";
  };

  const logout = () => {
    // Appel au serveur pour terminer la session
    axios
      .post("https://2ff3-87-67-150-183.ngrok-free.app/api/auth/logout")
      .then(() => {
        setUser(null);
        // Rediriger vers la page de connexion ou la page d'accueil après la déconnexion
      })
      .catch((error) => console.error("Erreur lors de la déconnexion", error));
  };

  return (
    <AuthContext.Provider value={{ user, setUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
