import { useEffect, useState, useRef } from "react";
import Card from "./card";
import styled from "styled-components";
import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from "@chakra-ui/react";
import Title from "../../../components/Title";

const PrimaryColor = "#0e1620";

const uniqueElementsArray = [
  {
    type: "Luffy",
    image: require(`../img/luffy.jpg`),
  },
  {
    type: "Nami",
    image: require(`../img/nami.jpg`),
  },
  {
    type: "Franky",
    image: require(`../img/franky.jpg`),
  },
  {
    type: "Zoro",
    image: require(`../img/zoro.jpg`),
  },
  {
    type: "Sanji",
    image: require(`../img/sanji.jpg`),
  },
  {
    type: "Brook",
    image: require(`../img/brook.jpg`),
  },
];

function shuffleCards(array) {
  const length = array.length;
  for (let i = length; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * i);
    const currentIndex = i - 1;
    const temp = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temp;
  }
  return array;
}

const MemoryGame = styled.section`
  .bold {
    font-weight: 600;
    text-transform: uppercase;
  }
  .App {
    width: 100%;
    height: 100%;

    header {
      position: relative;
      width: 100%;
      text-align: center;
      margin-bottom: 8px;

      > div {
        font-size: 15px;
        width: 324px;
        text-align: center;
        margin: 0 auto;
      }
    }

    footer {
      width: 360px;
      position: relative;
      margin: 0 auto;
      padding: 10px 4px;
      margin-top: 10px;

      .score {
        justify-content: center;
        display: flex;

        div {
          padding: 8px;
        }
      }

      .restart {
        display: flex;
        justify-content: center;
      }
    }

    .container {
      display: grid;
      grid-template-columns: repeat(3, 95px);
      grid-template-rows: repeat(4, 95px);
      gap: 5px;
      justify-items: center;
      align-items: stretch;
      margin: 0 auto;
      max-width: 320px;
      perspective: 100%;
    }
  }
`;

export default function App() {
  const [cards, setCards] = useState(
    shuffleCards.bind(null, uniqueElementsArray.concat(uniqueElementsArray))
  );

  const [Rcounter, setRcounter] = useState("0");

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (Rcounter === "1") {
      setIsModalOpen(true);
    }
  }, [Rcounter]);
  const [openCards, setOpenCards] = useState([]);
  const [clearedCards, setClearedCards] = useState({});
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const [moves, setMoves] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [reloadKey, setReloadKey] = useState(0);

  const [bestScore, setBestScore] = useState(
    JSON.parse(localStorage.getItem("bestScore")) || Number.POSITIVE_INFINITY
  );
  const timeout = useRef(null);

  const disable = () => {
    setShouldDisableAllCards(true);
  };
  const enable = () => {
    setShouldDisableAllCards(false);
  };

  const checkCompletion = () => {
    if (Object.keys(clearedCards).length === uniqueElementsArray.length) {
      setShowModal(true);
      const highScore = Math.min(moves, bestScore);
      setBestScore(highScore);
      localStorage.setItem("bestScore", highScore);
    }
  };
  const evaluate = () => {
    const [first, second] = openCards;
    enable();
    if (cards[first].type === cards[second].type) {
      setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }));
      setOpenCards([]);
      return;
    }
    // This is to flip the cards back after 500ms duration
    timeout.current = setTimeout(() => {
      setOpenCards([]);
    }, 500);
  };
  const handleCardClick = (index) => {
    if (openCards.length === 1) {
      setOpenCards((prev) => [...prev, index]);
      setMoves((moves) => moves + 1);
      disable();
    } else {
      clearTimeout(timeout.current);
      setOpenCards([index]);
    }
  };

  useEffect(() => {
    let timeout = null;
    if (openCards.length === 2) {
      timeout = setTimeout(evaluate, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [openCards]);

  useEffect(() => {
    checkCompletion();
  }, [clearedCards]);
  const checkIsFlipped = (index) => {
    return openCards.includes(index);
  };

  const checkIsInactive = (card) => {
    return Boolean(clearedCards[card.type]);
  };

  const handleRestart = () => {
    setClearedCards({});
    setOpenCards([]);
    setReloadKey(reloadKey + 1);
    setShowModal(false);
    setMoves(0);
    setShouldDisableAllCards(false);
    // set a shuffled deck of cards
    setCards(shuffleCards(uniqueElementsArray.concat(uniqueElementsArray)));
  };

  return (
    <MemoryGame key={reloadKey}>
      <div className="App">
        <div className="container">
          {cards.map((card, index) => {
            return (
              <Card
                key={index}
                card={card}
                index={index}
                isDisabled={shouldDisableAllCards}
                isInactive={checkIsInactive(card)}
                isFlipped={checkIsFlipped(index)}
                onClick={handleCardClick}
              />
            );
          })}
        </div>

        {showModal && (
          <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
            <ModalOverlay />
            <ModalContent
              backgroundColor={PrimaryColor}
              w={"95%"}
              p={4}
              alignItems={"center"}
              m={"auto"}
            >
              <ModalHeader color={"white"} textAlign="center" pt={6}>
                <Title color={"white"} text="Merci d'avoir participé !" />
              </ModalHeader>
              <ModalBody textAlign="center" p={0}>
                <Button
                  color="white"
                  backgroundColor={"orange"}
                  borderRadius="10px"
                  onClick={() => handleRestart()}
                >
                  Rejouer
                </Button>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
      </div>
    </MemoryGame>
  );
}
