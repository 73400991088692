import { useEffect, useState, useRef } from "react";
import Card from "./card";
import styled from "styled-components";
import {
  Button,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
} from "@chakra-ui/react";
import Title from "../../../components/Title";

import Checker from "../../../components/checker";
import { SignIn } from "../helpers/utils";

const PrimaryColor = "#d9f1f1";

const uniqueElementsArray = [
  {
    type: "Card1",
    image: require(`../img/card-1.jpg`),
  },
  {
    type: "Card2",
    image: require(`../img/card-2.jpg`),
  },
  {
    type: "Card3",
    image: require(`../img/card-3.jpg`),
  },
  {
    type: "Card4",
    image: require(`../img/card-4.jpg`),
  },
  {
    type: "Card5",
    image: require(`../img/card-5.jpg`),
  },
  {
    type: "Card6",
    image: require(`../img/card-6.jpg`),
  },
];

function shuffleCards(array) {
  const length = array.length;
  for (let i = length; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * i);
    const currentIndex = i - 1;
    const temp = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temp;
  }
  return array;
}

const MemoryGame = styled.section`
  .bold {
    font-weight: 600;
    text-transform: uppercase;
  }
  .App {
    width: 100%;
    height: 100%;

    header {
      position: relative;
      width: 100%;
      text-align: center;
      margin-bottom: 8px;

      > div {
        font-size: 15px;
        width: 324px;
        text-align: center;
        margin: 0 auto;
      }
    }

    footer {
      width: 360px;
      position: relative;
      margin: 0 auto;
      padding: 10px 4px;
      margin-top: 10px;

      .score {
        justify-content: center;
        display: flex;

        div {
          padding: 8px;
        }
      }

      .restart {
        display: flex;
        justify-content: center;
      }
    }

    .container {
      display: grid;
      grid-template-columns: repeat(3, 95px);
      grid-template-rows: repeat(4, 95px);
      gap: 5px;
      justify-items: center;
      align-items: stretch;
      margin: 0 auto;
      max-width: 320px;
      perspective: 100%;
    }
  }
`;

export default function App() {
  const [cards, setCards] = useState(
    shuffleCards.bind(null, uniqueElementsArray.concat(uniqueElementsArray))
  );

  const [Rcounter, setRcounter] = useState("0");

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (Rcounter === "1") {
      setIsModalOpen(true);
    }
  }, [Rcounter]);
  const [openCards, setOpenCards] = useState([]);
  const [clearedCards, setClearedCards] = useState({});
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const [moves, setMoves] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [reloadKey, setReloadKey] = useState(0);
  const [checker, setChecker] = useState(false);

  const [bestScore, setBestScore] = useState(
    JSON.parse(localStorage.getItem("bestScore")) || Number.POSITIVE_INFINITY
  );
  const timeout = useRef(null);

  const disable = () => {
    setShouldDisableAllCards(true);
  };
  const enable = () => {
    setShouldDisableAllCards(false);
  };

  const checkCompletion = () => {
    if (Object.keys(clearedCards).length === uniqueElementsArray.length) {
      setShowModal(true);
      const highScore = Math.min(moves, bestScore);
      setBestScore(highScore);
      localStorage.setItem("bestScore", highScore);
    }
  };
  const evaluate = () => {
    const [first, second] = openCards;
    enable();
    if (cards[first].type === cards[second].type) {
      setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }));
      setOpenCards([]);
      return;
    }
    // This is to flip the cards back after 500ms duration
    timeout.current = setTimeout(() => {
      setOpenCards([]);
    }, 500);
  };
  const handleCardClick = (index) => {
    if (openCards.length === 1) {
      setOpenCards((prev) => [...prev, index]);
      setMoves((moves) => moves + 1);
      disable();
    } else {
      clearTimeout(timeout.current);
      setOpenCards([index]);
    }
  };

  useEffect(() => {
    let timeout = null;
    if (openCards.length === 2) {
      timeout = setTimeout(evaluate, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [openCards]);

  useEffect(() => {
    checkCompletion();
  }, [clearedCards]);
  const checkIsFlipped = (index) => {
    return openCards.includes(index);
  };

  const checkIsInactive = (card) => {
    return Boolean(clearedCards[card.type]);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    question: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    question: false,
  });

  // Fonction de gestion des changements dans les champs de formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Fonction de validation du formulaire
  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = true;
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email))
      errors.email = true;
    if (
      !formData.question ||
      isNaN(formData.question) ||
      parseInt(formData.question, 10) <= 0
    )
      errors.question = true;
    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  // Fonction de gestion de l'envoi du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();
    setChecker(true);
    if (validateForm()) {
      SignIn(formData);
      console.log("Form Data:", formData);
      // Ici, vous pouvez également fermer la modale et réinitialiser le formulaire si nécessaire
    }
  };

  const handleRestart = () => {
    setClearedCards({});
    setOpenCards([]);
    setReloadKey(reloadKey + 1);
    setShowModal(false);
    setMoves(0);
    setShouldDisableAllCards(false);
    // set a shuffled deck of cards
    setCards(shuffleCards(uniqueElementsArray.concat(uniqueElementsArray)));
  };

  return (
    <MemoryGame key={reloadKey}>
      <div className="App">
        <div className="container">
          {cards.map((card, index) => {
            return (
              <Card
                key={index}
                card={card}
                index={index}
                isDisabled={shouldDisableAllCards}
                isInactive={checkIsInactive(card)}
                isFlipped={checkIsFlipped(index)}
                onClick={handleCardClick}
              />
            );
          })}
        </div>

        {showModal && (
          <Modal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent
              backgroundColor={"#3f7cc3"}
              w={"95%"}
              p={4}
              alignItems={"center"}
              m={"auto"}>
              <ModalHeader color={"white"} textAlign="center" pt={2}>
                {checker ? (
                  <Title
                    color={"white"}
                    text="Votre inscription a bien été enregistrée"
                  />
                ) : (
                  <Title color={"white"} text="Merci d'avoir participé !" />
                )}
              </ModalHeader>
              <ModalBody textAlign="center" p={0}>
                {checker ? (
                  <Checker color={"white"} />
                ) : (
                  <form onSubmit={handleSubmit}>
                    <FormControl isInvalid={formErrors.name}>
                      <FormLabel htmlFor="name" color={"white"}>
                        Nom
                      </FormLabel>
                      <Input
                        color={"white"}
                        id="name"
                        mb={2}
                        borderColor={"white"}
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {formErrors.name && (
                        <FormErrorMessage>Le nom est requis.</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl isInvalid={formErrors.email}>
                      <FormLabel color={"white"} htmlFor="email">
                        Email
                      </FormLabel>
                      <Input
                        color={"white"}
                        id="email"
                        mb={2}
                        borderColor={"white"}
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {formErrors.email && (
                        <FormErrorMessage>
                          L'email est invalide.
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl isInvalid={formErrors.question}>
                      <FormLabel color={"white"} htmlFor="question">
                        Combien de personnes participeront à ce jeu ?
                      </FormLabel>
                      <Input
                        color={"white"}
                        id="question"
                        mb={2}
                        borderColor={"white"}
                        name="question"
                        type="number"
                        value={formData.question}
                        onChange={handleChange}
                      />
                      {formErrors.question && (
                        <FormErrorMessage>
                          Une réponse valide est requise.
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <Button
                      mt={2}
                      colorScheme="blue"
                      type="submit"
                      color={"#3f7cc3"}
                      background={"white"}>
                      Envoyer
                    </Button>
                  </form>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
      </div>
    </MemoryGame>
  );
}
