// import Admin from "../pages/Admin/admin";
import AdminAppHome from "../pages/App/App";
import MigrosAppAdminLogin from "../pages/App/admin/pages/Login";
import MigrosAppAdminLoginBuzz from "../pages/App/admin/pages/LoginBuzz";
import MigrosAppAdminHomeBuzz from "../pages/App/admin/pages/Global";
import MigrosAppAdminHome from "../pages/App/admin/pages/Global";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { AuthProvider } from "../pages/App/admin/helper/AuthContext";
import MigroHalloweenQuizPerso from "../pages/Migros/Halloween/QuizPerso/Test_pages";
import MigroHalloweenPeur from "../pages/Migros/Halloween/QuestionPeur/Peur_pages";
import MigroHalloweenPlaylist from "../pages/Migros/Halloween/Playlist/Playlist_pages";
import MigroHalloweenScreamer from "../pages/Migros/Halloween/VideoScreamer/Video_pages";
import FormulaireVisite from "../pages/FormulaireVisite/App";
import Tamagotchi from "../pages/App/games/Tamagotchi/App";
import TestLogin from "../pages/Test/app";
import ProtectedRoute from "../pages/App/admin/composants/ProtectedRoute"; // Chemin vers votre ProtectedRoute
import MayTheFourth from "../pages/App/games/MayThe42024/App";
import MemoryGameStitch from "../pages/App/games/MemoryGameStitch/MemoryGame";

function AdminRoutes() {
  const adminRoutes = [
    {
      path: "/test/login",
      element: <TestLogin />,
    },
    {
      path: "/admin/login/buzz",
      element: <MigrosAppAdminLoginBuzz />,
    },
    {
      path: "/admin/login",
      element: <MigrosAppAdminLogin />,
    },
    {
      path: "/",
      element: <AdminAppHome />,
    },
    {
      path: "/admin/home",
      element: <MigrosAppAdminHome />,
    },
    {
      path: "/admin/dev/tamagotchi",
      element: <Tamagotchi />,
    },
    {
      path: "/maythefourth",
      element: <MayTheFourth />,
    },
    {
      path: "/games/tamagotchi",
      element: <Tamagotchi />,
    },

    {
      path: "/halloween/quizperso",
      element: <MigroHalloweenQuizPerso />,
    },
    {
      path: "/halloween/peur",
      element: <MigroHalloweenPeur />,
    },
    {
      path: "/halloween/playlist",
      element: <MigroHalloweenPlaylist />,
    },

    {
      path: "/halloween/video",
      element: <MigroHalloweenScreamer />,
    },
    {
      path: "/mgeek/formulaire-visite",
      element: <FormulaireVisite />,
    },
    {
      path: "/stitch/memory-game",
      element: <MemoryGameStitch />,
    },
  ];

  const theme = extendTheme({
    colors: {
      primary: "#704488",
      white: "#ffffff",
      blue: "#4080c5",
      orange: "#f07c44",
      orange_second: "#eb661b",
      orange_black: "#9e4513",
    },
    components: {
      Heading: {
        baseStyle: {
          fontFamily: "'MigrosTitle', sans-serif",
        },
      },
    },
    styles: {
      global: {
        // Appliquer la police à tous les éléments sauf les en-têtes
        "body, button, input, textarea, select, a": {
          fontFamily: "'MigrosText', sans-serif",
          fontSize: "12px",
        },
      },
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Routes>
          {adminRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default AdminRoutes;
