import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  Heading,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  AddIcon,
  ChevronRightIcon,
  CheckIcon,
} from "@chakra-ui/icons";
import {
  deletePlaylist,
  getContent,
  postPlaylist,
  putContentPlaylistTitle,
} from "../../helper/utils";
import DeleteModal from "../composants/DeleteModal";
import { AuthContext } from "../helper/AuthContext";

function Playlists() {
  const [playlists, setPlaylists] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [newPlaylistUrl, setNewPlaylistUrl] = useState("");
  const { username } = useContext(AuthContext);
  const [allData, setAlldata] = useState([]);
  const [icon, setIcon] = useState(<ChevronRightIcon />);
  const [title, setTitle] = useState();

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const content = await getContent();
        setAlldata(content[0].fr.playlist);
        setPlaylists(content[0].fr.playlist.playlists);
        setTitle(content[0].fr.playlist.header.text);
      } catch (error) {
        console.error("Erreur lors de la récupération du contenu:", error);
      }
    };

    fetchContent();
  }, []);

  const handleAdd = () => {
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const confirmAdd = () => {
    setPlaylists([...playlists, newPlaylistUrl]);
    const data = { playlistUrl: newPlaylistUrl, username: username };
    postPlaylist(data);
    setAddModalOpen(false);
    setNewPlaylistUrl(""); // Réinitialiser l'URL
  };

  const handleDeleteConfirmation = (index) => {
    setSelectedPlaylist(index);
    setDeleteModalOpen(true);
  };

  const closeModal = () => {
    setDeleteModalOpen(false);
  };

  const confirmDelete = async () => {
    try {
      const data = {
        id: selectedPlaylist,
        username: username,
        playlistUrl: playlists[selectedPlaylist],
      };
      await deletePlaylist(data); // Supprimer la playlist ici
      setPlaylists(playlists.filter((_, index) => index !== selectedPlaylist)); // Mettre à jour l'état local
      setDeleteModalOpen(false); // Fermer le modal
    } catch (error) {
      console.error("Erreur lors de la suppression de la playlist:", error);
    }
  };

  const handleEditTitle = async () => {
    putContentPlaylistTitle(title);
    setIcon(<CheckIcon />);
    setTimeout(() => {
      setIcon(<ChevronRightIcon />);
    }, 1000);
  };

  return (
    <>
      <Box w={"600px"} mb={10}>
        <Heading fontSize={"20px"} mb={2}>
          Titre
        </Heading>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Input
            placeholder={allData?.header?.text}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <IconButton
            icon={icon}
            colorScheme="green"
            ml={2}
            onClick={handleEditTitle}
          />
        </Box>
      </Box>
      <Box>
        <Flex flexDirection="column" gap={4}>
          {playlists.map((playlist, index) => (
            <Flex
              key={index}
              justifyContent="space-between"
              alignItems="center"
            >
              <a href={playlist} target="_blank" rel="noopener noreferrer">
                {playlist}
              </a>
              <IconButton
                icon={<DeleteIcon />}
                colorScheme="red"
                onClick={() => handleDeleteConfirmation(index)}
              />
            </Flex>
          ))}
        </Flex>
        <IconButton
          onClick={handleAdd}
          icon={<AddIcon fontSize={"40px"} />}
          colorScheme="green"
          position={"fixed"}
          right={"40px"}
          w={"50px"}
          height={"50px"}
          p={10}
          borderRadius={"full"}
          bottom={"40px"}
        />

        <Modal isOpen={isAddModalOpen} onClose={closeAddModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Ajouter une nouvelle playlist</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                placeholder="URL de la playlist"
                value={newPlaylistUrl}
                onChange={(e) => setNewPlaylistUrl(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={confirmAdd}>
                Ajouter
              </Button>
              <Button variant="ghost" onClick={closeAddModal}>
                Annuler
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={closeModal}
          onConfirm={confirmDelete}
        />
      </Box>
    </>
  );
}

export default Playlists;
