import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalCloseButton,
  Button,
  Text,
} from "@chakra-ui/react";

import Pwabtn from "./pwabtn";

// La fonction de formatage du temps
function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const paddedHours = String(hours).padStart(2, "0");
  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${paddedHours}h${paddedMinutes}m${paddedSeconds}s`;
}

export const TimerModal = ({ isOpen, onClose, timeLeft }) => {
  // Appliquer le formatage ici
  const formattedTimeLeft = formatTime(timeLeft);

  return (
    <Modal isOpen={isOpen} >
      <ModalOverlay />
      <ModalContent w={"95%"} m={"auto"} bg={"#3b347f"} color={"white"}>
        <Text
          fontSize={"20px"}
          textAlign={"center"}
          fontWeight={"bold"}
          pt={"16px"}>
          Revenez dans :
          <br />
          <Text pb={"16px"}>{formattedTimeLeft}</Text>
        </Text>
        <Pwabtn />
      </ModalContent>
    </Modal>
  );
};

export default TimerModal;
