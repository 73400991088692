import axios from "axios";
const dbURL = "https://r6g-app.onrender.com";

export const getApp = async () => {
  try {
    const response = await axios.get(`${dbURL}/generator/app`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};
