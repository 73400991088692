import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Box,
  VStack,
  Text,
  Image,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Button,
  Link,
} from "@chakra-ui/react";
import { getNews } from "../helper/utils";
import Title from "../components/Title";
import Header from "../components/Header";
import TranslationContext from "../helper/TranslationContext";

export default function News() {
  const [news, setNews] = useState([]);
  // const [page, setPage] = useState(1);
  const [selectedNews, setSelectedNews] = useState(null);
  // const [hasScrolled, setHasScrolled] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const containerRef = useRef(null);
  const modalContentRef = useRef(null);
  const translations = useContext(TranslationContext);
  const newsTranslation = translations[0]?.fr?.news;

  const fetchItems = async () => {
    const allItems = await getNews();
    setNews(allItems.reverse());
  };

  useEffect(() => {
    fetchItems();
  }, []);

  // const loadMoreNews = () => {
  //   setPage(page + 1);
  // };

  const handleNewsClick = (newsItem) => {
    setSelectedNews(newsItem);
    onOpen();
  };

  // const handleScroll = () => {
  //   const container = containerRef.current;
  //   if (
  //     container.scrollHeight - container.scrollTop ===
  //     container.clientHeight
  //   ) {
  //     setHasScrolled(true);
  //   }
  // };

  return (
    <Box
      p="2"
      h="100vh"
      overflowY="scroll"
      ref={containerRef}
      // onScroll={handleScroll}
    >
      <Header p="4" text={newsTranslation?.header.text} />

      <VStack spacing={4} gap={2} pt={2}>
        {news.map((newsItem, index) => (
          <Box
            key={index}
            borderRadius="md"
            overflow={"hidden"}
            position="relative"
            boxShadow="1px 1px 5px rgba(0, 0, 0, 0.5)"
            onClick={() => handleNewsClick(newsItem)}>
            <Text
              position={"absolute"}
              color={"white"}
              p={"8px 8px"}
              zIndex={99}
              right={0}
              textShadow={"1px 1px 2px black"}>
              {newsItem.date}
            </Text>
            <Image src={newsItem.image} alt={newsItem.title} />
            <Flex
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bg="rgba(0, 0, 0, 0.2)"
              justifyContent="flex-end"
              alignItems="flex-end"
              borderRadius={"md"}>
              <Text
                color={"white"}
                m={0}
                position={"absolute"}
                bottom={0}
                pl={2}
                w={"100%"}
                background={"rgba(241, 103, 36, 0.7)"}>
                {newsItem.title}
              </Text>
            </Flex>
          </Box>
        ))}
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w="95%" pb={6} overflow={"hidden"} m={"auto"}>
          <Image src={selectedNews?.image} alt={selectedNews?.title} />
          <ModalHeader pt={4} pb={0}>
            <Text color={"black"} opacity={0.5} fontSize={"12px"}>
              {" "}
              {selectedNews?.date}
            </Text>
            <Box
              width={"50px"}
              height={"2px"}
              bg={"blue"}
              opacity={"0.5"}
              m={"5px 0"}></Box>
            <Title text={selectedNews?.title} color={"blue"}></Title>
          </ModalHeader>
          <ModalCloseButton color={"white"} />
          <Flex position={"relative"}>
            <ModalBody
              mt={4}
              pt={0}
              maxH={"300px"}
              overflow={"scroll"}
              ref={modalContentRef}>
              {selectedNews?.content}
              {selectedNews?.link && (
                <Button
                  m={"10px auto 0 auto"}
                  display={"block"}
                  color={"white"}
                  textTransform={"uppercase"}
                  _hover={{ bg: "blue", color: "white" }}
                  bg={"blue"}>
                  <Link href={selectedNews?.link}>Voir la vidéo</Link>
                </Button>
              )}

              {/* <Box
                width="4px"
                height={`${scrollProgress}%`} // Set the height based on scroll progress
                bg="linear-gradient(225deg, rgba(55,121,188,1) 0%, rgba(118,73,141,1) 100%)"
                // Set the background color of the progress bar
                borderRadius="md"
                position={"absolute"}
                right={3}
                top={-10}
              /> */}
            </ModalBody>
          </Flex>
        </ModalContent>
      </Modal>
    </Box>
  );
}
